html body {
  margin: 0;
  padding: 0;
  font-family: Open Sans, sans-serif;
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
  font-size: 13.5px;
}

html {
  /* the only true one for rem*/
  font-size: 13.5px;
}

body .ReactTable {
  border: 0;
}

/* top header */
.ReactTable .rt-thead.-headerGroups {
  display: none;
}

.ReactTable .rt-thead.-filters {
  /* margin-top: 61px; */
}

/* column header */
.ReactTable .rt-thead.-header {
  height: 61px;
  color: RGBA(0, 0, 0, 0.87);
  /* width: 60%; */
  /* position: fixed; */
  background-color: white;
  /* z-index: 1; */
}

body .ReactTable .rt-thead.-header {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ReactTable .rt-th.rt-resizable-header {
  align-self: center;
  height: 61px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .ReactTable .rt-thead .rt-tr {
  flex: 1 0 100%;
} */

/* coin row */
body .ReactTable.-striped .rt-tr.-odd,
body .rthfc.-striped .rt-tr.-odd .rt-td {
  background: #0689f305;
}

body .coinlisting .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background-color: #f2f2f2;
}

.ReactTable .rt-tbody {
  padding-bottom: 61px;
}

body .ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(255, 64, 129, 0.3);
}

.ReactTable .rt-tbody .rt-td {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* sort underline header */
.ReactTable .rt-thead .rt-tr .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-tr .rt-td.-sort-desc {
  -webkit-box-shadow: inset 0 -3px 0 0;
  box-shadow: inset 0 -3px 0 0;
}

.ReactTable .rt-thead .rt-tr .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-tr .rt-td.-sort-asc {
  -webkit-box-shadow: inset 0 3px 0 0;
  box-shadow: inset 0 3px 0 0;
}

/* cell */
body .ReactTable .rt-tbody .rt-td,
body .ReactTable .rt-thead .rt-td {
  border: 0px;
}

body .ReactTable .rt-thead .rt-th {
  border: 0px;
}

body .helptoolbar {
  width: 100%;
  justify-content: space-between;
  padding: 0;
}

.helpbartitle {
  padding-left: 16px;
}

body .helper ul li div h3,
body .helper ul li div p,
body div button.appclosebutton {
  font-family: Open Sans, sans-serif;
}

body div.helptoolbar button.appclosebutton {
  color: white;
}

body .helper header {
  position: relative;
}

body .ReactTable .rt-thead.-filters .rt-th {
  border-right: 0;
  padding: 0;
  padding-bottom: 20px;
}

body .ReactTable .rt-thead.-filters {
  border-bottom: 0;
}

body .coinlisting {
  padding: 0;
  margin-top: 0;
  background-color: white;
  display: inline-block;
  width: 60%;
  border-right: 1px solid #f9f9f9;
}

body .itemInfo {
  color: rgba(0, 0, 0, 0.54);
  padding-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 0.875rem;
}

.coinsubsection_info_link {
  margin-bottom: 5px;
}

body .coinlink {
  color: rgba(34, 150, 243);
  text-decoration: none;
}

.ReactTable .pagination-bottom .-pagination {
  height: 61px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.25);
  border-top: 0;
  padding: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 60%;
  background-color: white;
}

.ReactTable .pagination-bottom .-pagination .-btn {
  background: rgba(255, 255, 255, 0.3);
  color: rgba(0, 0, 0, 0.54);
}

.ReactTable .pagination-bottom .-pagination .-btn:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.34);
  color: white;
}

.fabbutton > ul {
  margin-bottom: 70px;
}

@media (max-width: 700px) {
  .ReactTable .rt-thead.-header {
    /* position: relative; */
  }

  .ReactTable .rt-thead.-filters {
    margin-top: 0;
  }
}

@media (max-width: 500px) {
  .ReactTable .pagination-bottom .-pagination .-pageInfo {
    display: none;
  }
}

.ReactTable .rt-tr {
  /* padding-right: 25px; */
}

.ReactTable .rt-tr-group .rt-tr div:first-child {
  /* left: 0;
  position: absolute;
  top: auto; */
  /* width: 14.28%;
  min-width: 14.28%; */
}

.ReactTable .rt-tr-group .rt-tr div:nth-child(2) {
  /* margin-left: 14.28%; */
}

body .settingsdialog {
  right: 0;
  left: unset;
  width: 50%;
}

@media (min-width: 960px) {
  body .searchsnackbar {
    top: 0;
    left: 0;
  }
}

.searchsnackbar div {
  background-color: white;
  font-family: Open Sans, sans-serif;
}

.searchsnackbar div:nth-child(2) {
  padding: 0;
  margin: 0;
  width: 100%;
}

.searchsnackbarform {
  width: 100%;
}

.searchsnackbarform input {
  color: #2296f3;
  text-align: center;
  height: 30px;
}

body .searchsnackbarform > div:first-child:hover:before {
  border-bottom: 2px solid #2296f3;
}

.searchsnackbarform .clearbutton:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.searchsnackbarform .clearbutton {
  color: rgba(0, 0, 0, 0.54);
}

.searchsnackbar button {
  font-family: Open Sans, sans-serif;
}

.searchsnackbarform div::before {
  border-bottom: 1px solid #2296f3;
}

body .settingsfabbutton {
  position: fixed;
  z-index: 100;
  left: 10px;
  top: 10px;
  width: 30px;
  height: 15px;
  background-color: white;
  border-radius: 20%;
}

/* body .searchfabbutton {
  margin: 0;
  top: auto;
  right: 37px;
  bottom: 105px;
  left: auto;
  position: fixed;
} */

body .shortkeysnackbarmsg {
  font-family: Open Sans, sans-serif;
}

.ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td:first-child,
.ReactTable .rt-thead .rt-tr .rt-th:first-child {
  /* padding-left: 2%; */
  /* justify-content: flex-start; */
  border: 0px !important;
}

@media (max-width: 1500px) {
  .ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td:first-child {
    /* padding-left: 10px; */
    /* justify-content: flex-start; */
  }

  .rt-tr-group .coinsubsection {
    padding-left: 10px;
  }
}

@media (max-width: 1100px) {
  .ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td:first-child {
    /* padding-left: 10px; */
    /* justify-content: flex-start; */
  }

  .rt-tr-group .coinsubsection {
    padding-left: 10px;
  }

  .rt-tr-group .coinsubsectionborder {
    width: 1px;
  }

  .rt-tr-group .coinsubsectiontopborder {
    width: 1px;
  }
}

@media (max-width: 600px) {
  .ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td:first-child {
    padding-left: 5px;
    /* justify-content: flex-start; */
  }

  .rt-tr-group .coinsubsection {
    padding-left: 5px;
  }

  .ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td {
    max-height: 45px;
  }
}

@media (max-width: 600px) {
  body .searchfabbutton {
    right: 17px;
    bottom: 105px;
    height: 25px;
    width: 37px;
  }

  body .settingsdialog {
    width: 90vw;
  }

  .coinsubsection {
    height: 40vh;
    padding-top: 8px;
  }
}

@media (max-width: 400px) {
  .coinsubsection {
    font-size: 13px;
  }
}

@media (max-width: 359px) {
  body .coinsubsection .coinsubsection_title {
    margin: 10px;
    margin-bottom: 0;
  }

  .coinsubsection {
    font-size: 11px;
  }
}

.coinsubsection {
  padding-left: 2%;
  position: relative;
}

.coinsubsectionborder {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 6px;
}

.coinsubsectiontopborder {
  position: absolute;
  top: -61px;
  left: 0;
  height: 61px;
  width: 6px;
}

div[data-highcharts-chart] {
  width: 39vw;
  position: fixed;
  bottom: 0;
  left: auto;
  right: auto;
}

@media (max-width: 1020px) {
  body .tabmenu {
    position: fixed;
    z-index: 9999999;
  }
}

@media (max-width: 1020px) {
  div[data-highcharts-chart] {
    width: 80vw;
    margin-bottom: 61px;
    height: 50vh;
  }
}

@media (max-width: 450px) {
  .tabmenu button:last-child {
    display: none;
  }
}

@media (max-width: 600px) {
  .rt-tr-group .coinsubsection {
    position: absolute;
    margin-top: 61px;
  }
}

body .ReactTable .rt-tbody .rt-tr-group {
  border-bottom: solid 0 rgba(0, 0, 0, 0.05);
}

.coinInfoBar {
  overflow: auto;
  display: inline-block;
  position: fixed;
  top: 0;
  left: 60%;
  z-index: 2;
  border-left: 3px solid #f9f9f9;
  width: 40%;
  height: 100%;
  background-color: white;
}

.selectacoin {
  text-align: center;
  margin-top: 50px;
}

@media (min-width: 1020px) {
  body .settingsdialog {
    display: none;
  }
}

.tabmenu {
  position: fixed;
  background-color: white;
  z-index: 2;
}

.wideSideBar .tabmenu {
  width: 40%;
  bottom: 0;
  height: 61px;
  border-top: 1px solid #eee;
}

/* .coinInfoBarContent {
  margin-top: 60px;
} */

/* ATTEMPT TO MAKE THE SITE MOBILE "FIRST" */
@media (max-width: 1020px) {
  .settingsdialog div[data-highcharts-chart] {
    height: 50vh;
    margin-bottom: 61px;
  }

  .ReactTable .pagination-bottom .-pagination {
    width: 100%;
  }

  body .coinlisting {
    width: 100%;
  }

  .ReactTable .rt-thead.-header {
    /* width: 100%; */
  }

  .tabmenu {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 2;
  }

  .wideSideBar {
    display: none;
  }

  .coinInfoBar {
    left: 0;
    border-left: 0 solid #f9f9f9;
    width: 100%;
    background-color: white;
    overflow-y: scroll;
    position: inherit;
  }

  .coinInfoBarContent {
    position: inherit;
  }

  .helper {
    padding-bottom: 61px;
    height: 100vh;
    position: inherit;
  }
}

@media (max-width: 1024px) {
  body .coinsubsection_title {
    margin-bottom: 20px;
  }
}

.coinsubsection_title {
  text-align: center;
  color: #1d7dca;
  font-size: 3em;
  margin: 40px;
}

.coinsubsection_info_title {
  color: #7f7f7f;
  margin-bottom: 5px;
}

.coinsubsection_info {
  vertical-align: top;
}

.ReactTable .rt-thead .rt-tr .rt-th:first-child {
  /* left: 0;
  position: absolute;
  top: auto; */
  /* width: 14.28%;
  min-width: 14.28%; */
}

.ReactTable .rt-thead .rt-tr div:nth-child(2) {
  /* margin-left: 14.28%; */
}

body .ReactTable .rt-thead .rt-tr .rt-th.-sort-asc {
  -webkit-box-shadow: inset 0 3px 0 0 rgba(33, 150, 243, 1);
  box-shadow: inset 0 3px 0 0 rgba(33, 150, 243, 1);
}

body .ReactTable .rt-thead .rt-tr .rt-th.-sort-desc {
  -webkit-box-shadow: inset 0 -3px 0 0 rgba(33, 150, 243, 1);
  box-shadow: inset 0 -3px 0 0 rgba(33, 150, 243, 1);
}

body .ReactTable .pagination-bottom .-pagination .-btn:not([disabled]):hover {
  background: rgba(33, 150, 243, 1);
}

body .switchinput {
  display: inline-flex;
}

li.switchinput {
  width: inherit;
}

body .switchinputspan {
  display: block;
  margin-left: 18px;
}

body .radioinput {
  margin-left: 12px;
}

body .ReactTable .rt-thead .rt-tr .rt-th:first-child {
  /* position: absolute;
  top: auto; */
  background-color: white;
  /* height: 80px;
  z-index: 1;
  margin-top: -50px; */
}

/* remove ugly outline around the column names */
.rt-th.rt-resizable-header.-cursor-pointer:focus {
  outline: inherit;
}

.helper input[type='text'] {
  min-width: 200px;
}

div.searchsnackbarform {
  flex-direction: row;
}

.ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td {
  height: inherit;
}
.ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td span {
  white-space: normal;
}
