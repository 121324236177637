.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.coinsubsection_info {
  display: inline-block;
  padding: 20px;
}

.gamekultframe {
  width: 100%;
  border: 0;
  height: 90vh;
  margin-top: 10px;
}

button.backbutton {
  padding: 10px 15px;
}

@media (min-width: 1021px) {
  button.backbutton {
    display: none;
  }
}

@media (max-width: 1020px) {
  div.gametitle {
    margin-top: 0px;
  }
  .gamekultframe {
    margin-top: 0px;
  }
  .coinInfoBarContent {
    margin-top: 0px;
  }
  div.gameinfo {
    padding-bottom: 60px;
  }
}

.coinInfoBar {
  padding-bottom: 85px;
}

.namecell {
  width: 300px;
}
@media (max-width: 700px) {
  .namecell {
    width: 150px;
  }
  .ReactTable .rt-tbody .rt-td {
    height: inherit;
  }
  .ReactTable .rt-tbody .rt-tr-group .rt-tr .rt-td {
    max-height: none;
  }
  .ReactTable .rt-th,
  .ReactTable .rt-td {
    white-space: inherit;
  }
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  padding: 3px;
}

a {
  color: #2196f3;
}

.gameinfo {
  margin: 20px;
}

.gametitle {
  text-align: center;
  margin: 40px;
  margin-bottom: 0px;
  font-size: xx-large;
}
.gamegenres,
.gamethemes {
  text-align: center;
  margin: 10px;
}

.gameurl {
  text-align: center;
  margin-bottom: 40px;
}
.gameurl a {
  text-decoration: none;
}

.gamesummary {
  margin-bottom: 40px;
}

.gameimg {
  margin-bottom: 20px;
}

.igdbbanner img {
  margin-top: 40px;
}

.igdbsloganhelp {
  min-width: 400px;
}

.helppartnersection {
  flex-wrap: wrap;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
  overflow: hidden;
  margin-bottom: 20px;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.reviewWrapper {
  margin-bottom: 20px;
}

.gamereviewstitle {
  margin-top: 40px;
}

.reviewtitle {
  margin-bottom: 10px;
}

.reviewtitleuserinfo {
  font-weight: normal;
  font-style: italic;
}

.reviewtitle a {
  text-decoration: none;
  font-weight: 500;
}

.steamidprogress {
  width: 30px;
  height: 30px;
  margin-top: 30px;
}

.hideme {
  display: none;
}

.imageloader {
  margin: 40px;
}

.steamidhelp {
  display: flex;
  margin-left: 27px;
  margin-bottom: 30px;
  font-size: smaller;
}
.sitetitle,
.sitesubtitle {
  display: none;
}

.igdblistcontainer {
  display: flex;
  flex-direction: column;
  justify-items: start;
}
.igdblistcontainer a {
  width: 162px;
}

.igdblist {
  margin-left: 18px;
}

.spotifyframe {
  flex: 1;
  align-self: center;
  margin: 40px;
  margin-top: 20px;
}
.soundtrackdialog {
  left: 40%;
  top: 25%;
  background-color: black;
  position: absolute;
  z-index: 9;
}
.soundtrackdialog div div {
  background-color: black;
  color: white;
}
.soundtrackdialog div h6 {
  color: white;
  font-size: medium;
}
.soundtrackdialog div h6 div div div {
  padding-right: 22px;
}
.soundtrackdialog div h6 div:after {
  border-bottom: 0px;
}
.soundtrackdialog div h6 button,
.soundtrackdialog div h6 svg {
  color: white;
}
.noalbumfound {
  color: white;
}
.noalbumfound.invisible {
  visibility: hidden;
}

.openinspotify {
  text-decoration: none;
  color: white;
  text-transform: uppercase;
}
.searchinyoutube a {
  text-decoration: none;
  color: white;
  text-transform: lowercase;
  display: flex;
  flex-direction: row;
  margin: 5%;
  width: 90%;
  justify-content: center;
}
button.soundtrackPreviousButton {
  margin-left: 10px;
}

/* Onboarding rules */
body #root .onboarding .sitetitle {
  display: flex;
  font-family: 'Archivo Black', sans-serif;
  font-size: 6.7vw;
  color: #2296f3;
  margin-bottom: -20px;
  margin: 0px;
}
body #root .onboarding .sitesubtitle {
  font-size: xx-large;
  margin-top: 0px;
  letter-spacing: 4px;
  color: #004174;
  font-size: 2vw;
  display: flex;
}
body #root .onboarding.steamidhelp {
  justify-content: center;
  margin-left: 0px;
}
body #root .onboarding.coinlisting,
body #root .onboarding.tabmenu,
body #root .onboarding.settingsfabbutton,
body #root .onboarding.igdbbanner,
body #root .onboarding.settingscolumns,
body #root .onboarding.settingsexperimental {
  display: none;
  width: 0%;
}
body #root .onboarding.coinInfoBarContent {
  margin-top: 0px;
}
body #root .onboarding.coinInfoBar {
  border-left: 0px;
  width: 100%;
  left: inherit;
}
body #root .onboarding.helper {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
}
body #root .onboarding.helper fieldset,
body #root .onboarding.helper label {
  border-color: #2196f3;
  color: #2196f3;
}

button.nintendoswitchbutton {
  background-color: #e60012;
  margin: 10px;
}
button.nintendoswitchbutton:hover {
  background-color: #d20010;
}
button.steambutton {
  background-color: #000000;
  margin: 10px;
}

button.steambutton:hover {
  background-color: #333333;
}

.switchprogress svg {
  color: #e60012;
}

.listbuttoncontainer li {
  justify-content: center;
}

.hide {
  display: none;
}

.gamesummary span .morebutton {
  margin: 0;
  padding: 0;
  text-transform: lowercase;
  font-weight: 500;
  font-size: inherit;
  min-width: 0;
}

div#soundtrackpopup {
  display: flex;
  flex-wrap: wrap;
  width: 370px;
  background-color: black;
  color: white;
  top: 0;
  right: 0;
  position: absolute;
  z-index: 9999;
}

div#soundtrackpopup button {
  color: white;
}

div#soundtrackpopup div:first-child {
  padding: 0px 10px;
}

.iframePlugin {
  min-height: -webkit-fill-available;
}

.ReactTable .rt-tr-group {
  min-height: 60px;
}
